import { App } from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import { AxiosResponse, AxiosRequestConfig } from "axios";
import store from "@/store";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
  /**
   * @description property to share vue instance
   */
  public static vueInstance: App;

  /**
   * @description initialize vue axios
   */
  public static init(app: App<Element>) {
    ApiService.vueInstance = app;
    ApiService.vueInstance.use(VueAxios, axios);
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    ApiService.vueInstance.axios.interceptors.request.use(
      (config) => {
        const token = JwtService.getToken();
        const expiresAt = JwtService.getExpiresAt();
        if (Number(expiresAt) <= Date.now() && config.url !== "/oauth2/token") {
          console.log("token expired");

          ApiService.vueInstance.axios
            .post("/oauth2/token", {
              client_id: process.env.VUE_APP_API_CLIENT_ID,
              client_secret: process.env.VUE_APP_API_CLIENT_SECRET,
            })
            .then((response) => {
              JwtService.saveToken(response.data.access_token);
              JwtService.saveExpiresAt(
                String(Date.now() + response.data.expires_in * 1000)
              );
              config.headers["Authorization"] =
                "Bearer " + response.data.access_token;
              config.headers["x-domain"] = store.getters["domain/getDomain"];
            });
        } else {
          config.headers["Authorization"] = "Bearer " + token;
          config.headers["x-domain"] = store.getters["domain/getDomain"];
        }

        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
    ApiService.vueInstance.axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        console.log(error.response);
        if (
          error.response === undefined ||
          (error.response && error.response.status !== 401)
        ) {
          return Promise.reject(error);
        }
        console.log("la");
        return ApiService.vueInstance.axios
          .post("/oauth2/token", {
            client_id: process.env.VUE_APP_API_CLIENT_ID,
            client_secret: process.env.VUE_APP_API_CLIENT_SECRET,
          })
          .then((response) => {
            JwtService.saveToken(response.data.access_token);
            JwtService.saveExpiresAt(
              String(Date.now() + response.data.expires_in * 1000)
            );
            error.config.headers["Authorization"] =
              "Bearer " + response.data.access_token;
            error.config.headers["x-domain"] =
              store.getters["domain/getDomain"];

            return ApiService.vueInstance.axios.request(error.config);
          });
      }
    );
  }

  /**
   * @description set the default HTTP request headers
   */
  public static setHeader(): void {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
    ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
      "application/json+ld";
    ApiService.vueInstance.axios.defaults.headers.common["x-domain"] =
      store.getters["domain/getDomain"];
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static query(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    return ApiService.vueInstance.axios.get(resource, params);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @returns Promise<AxiosResponse>
   */
  public static get(
    resource: string,
    slug = "" as string,
    config?: AxiosRequestConfig | undefined
  ): Promise<AxiosResponse> {
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    return ApiService.vueInstance.axios.get(`${resource}/${slug}`, config);
  }

  /**
   * @description set the POST HTTP request
   * @param resource: string
   * @param data
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static post(
    resource: string,
    data,
    params?: AxiosRequestConfig | undefined
  ): Promise<AxiosResponse> {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
    ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
      "application/ld+json";
    ApiService.vueInstance.axios.defaults.headers.common["x-domain"] =
      store.getters["domain/getDomain"];
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL;

    return ApiService.vueInstance.axios.post(`${resource}`, data, params);
  }

  public static patch(
    resource: string,
    data,
    params?: AxiosRequestConfig | undefined
  ): Promise<AxiosResponse> {
    ApiService.vueInstance.axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${JwtService.getToken()}`;
    ApiService.vueInstance.axios.defaults.headers.common["x-domain"] =
      store.getters["domain/getDomain"];
    ApiService.vueInstance.axios.defaults.headers.common["Accept"] =
      "application/ld+json";
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL;

    return ApiService.vueInstance.axios.patch(`${resource}`, data, params);
  }

  public static ktPost(
    resource: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    ApiService.vueInstance.axios.defaults.baseURL =
      "https://preview.keenthemes.com/metronic8/laravel/api";
    return ApiService.vueInstance.axios.post(`${resource}`, params);
  }

  /**
   * @description send the UPDATE HTTP request
   * @param resource: string
   * @param slug: string
   * @param params: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
  }

  /**
   * @description Send the PUT HTTP request
   * @param resource: string
   * @param data: AxiosRequestConfig
   * @returns Promise<AxiosResponse>
   */
  public static put(
    resource: string,
    data: object,
    params?: AxiosRequestConfig | undefined
  ): Promise<AxiosResponse> {
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    return ApiService.vueInstance.axios.put(`${resource}`, data, params);
  }

  /**
   * @description Send the DELETE HTTP request
   * @param resource: string
   * @returns Promise<AxiosResponse>
   */
  public static delete(resource: string): Promise<AxiosResponse> {
    ApiService.vueInstance.axios.defaults.baseURL = process.env.VUE_APP_API_URL;
    return ApiService.vueInstance.axios.delete(resource);
  }
}

export default ApiService;
